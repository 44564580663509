const CABANG = [
  {
    label: "Bintaro",
    value: "BINTARO",
  },
  {
    label: "Bekasi",
    value: "BEKASI",
  },
  {
    label: "Depok",
    value: "DEPOK",
  },
  {
    label: "Serang",
    value: "SERANG",
  },
  {
    label: "Jogja",
    value: "JOGJA",
  },
  {
    label: "Lombok",
    value: "LOMBOK",
  },
];

const JENIS_KELAMIN = [
  {
    label: "Laki-laki",
    value: "LAKI-LAKI",
  },
  {
    label: "Perempuan",
    value: "PEREMPUAN",
  },
];

const KATEGORI_USER = [
  {
    label: "Priority",
    value: 1,
  },
  {
    label: "Umum",
    value: 0,
  },
];

export {CABANG, JENIS_KELAMIN, KATEGORI_USER};
