<template>
  <div>
    <!-- Tambah User -->
    <b-modal
      id="tambah-user"
      size="lg"
      title="Tambah Pengguna"
      cancel-title="Cancel"
      ok-title="Simpan"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="submitUser"
    >
      <validation-observer ref="registerForm" #default="{}">
        <b-form class="mt-2" @submit.prevent>
          <b-row>
            <!-- nama -->
            <b-col md="6">
              <b-form-group label="Nama" label-for="nama">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="nama"
                    placeholder="Nama"
                    v-model.trim="form.name"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col md="6">
              <b-form-group label="Email" label-for="email" class="w-100">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="email"
                    v-model.trim="form.email"
                    placeholder="john@example.com"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- whatsapp -->
            <b-col md="6">
              <b-form-group label="Whatsapp" label-for="whatsapp">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>+62</b-input-group-prepend>
                  <b-form-input
                    type="number"
                    id="whatsapp"
                    placeholder="Whatsapp"
                    v-model.number="form.no_wa"
                  />
                </b-input-group>
                <small class="text-danger">* tanpa 0 di depannya</small>
              </b-form-group>
            </b-col>

            <!-- sekolah -->
            <b-col md="6">
              <b-form-group
                label="Asal Sekolah"
                label-for="asal_sekolah"
                class="w-100"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="asal_sekolah"
                    v-model.trim="form.sekolah_asal"
                    placeholder="SMA/MA"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- tanggal lahir -->
            <b-col md="6">
              <b-form-group label="Tanggal Lahir" label-for="tanggal_lahir">
                <b-form-datepicker
                  id="tanggal_lahir"
                  v-model="form.tanggal_lahir"
                  class="mb-1"
                  placeholder="Tanggal Lahir"
                />
              </b-form-group>
            </b-col>

            <!-- jenis kelamin -->
            <b-col md="6">
              <b-form-group label="Jenis Kelamin">
                <div class="d-flex justify-content-start">
                  <b-form-radio
                    v-model="form.jenis_kelamin"
                    name="some-radios"
                    value="LAKI-LAKI"
                    >Laki Laki</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.jenis_kelamin"
                    name="some-radios"
                    value="PEREMPUAN"
                    class="ml-2"
                    >Perempuan</b-form-radio
                  >
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- provinsi -->
            <b-col md="6">
              <b-form-group label="Provinsi">
                <b-form-group>
                  <v-select
                    v-model="form.province_id"
                    placeholder="--Provinsi--"
                    @input="changeProvinsi"
                    :options="optionProvinsi"
                    :reduce="(propinsi) => propinsi.id"
                    label="name"
                  />
                </b-form-group>
              </b-form-group>
            </b-col>

            <!-- kab/kota -->
            <b-col md="6">
              <b-form-group label="Kab/Kota">
                <v-select
                  v-if="form.province_id"
                  v-model="form.city_id"
                  :loading="loadingKabKota"
                  @input="changeKabKota"
                  placeholder="--Kab/Kota--"
                  :options="optionKabKota"
                  :reduce="(kota) => kota.id"
                  label="name"
                />
                <v-select v-else disabled placeholder="Pilih provinsi dulu" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- kecamatan -->
            <b-col md="6">
              <b-form-group label="Kecamatan">
                <v-select
                  v-if="form.city_id && form.province_id"
                  v-model="form.district_id"
                  :loading="loadingKecamatan"
                  placeholder="--Kecamatan--"
                  :options="optionKecamatan"
                  :reduce="(district) => district.id"
                  label="name"
                />
                <v-select v-else disabled placeholder="Pilih Kab/Kota dulu" />
              </b-form-group>

              <b-form-group label="Role">
                <v-select
                  v-model="form.role"
                  placeholder="--ROLE--"
                  :options="ROLES"
                  :reduce="(role) => role.value"
                  label="label"
                />
              </b-form-group>
            </b-col>

            <!-- alamat -->
            <b-col md="6">
              <b-form-group label="Alamat" label-for="alamat" class="w-100">
                <b-form-textarea
                  v-model.trim="form.alamat"
                  id="alamat"
                  placeholder="Tuliskan Alamat anda"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- password -->
            <b-col md="6">
              <b-form-group
                label-for="register-password"
                label="Password"
                class="w-100"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="register-password"
                    v-model="form.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="register-password"
                    placeholder="············"
                    autocomplete="new-password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger"
                  >* panjang password minimal 7 karakter</small
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Edit User -->
    <b-modal
      id="edit-user"
      size="lg"
      title="Edit Pengguna"
      @hidden="cancelClick"
    >
      <template #modal-header>
        <h4>Edit Pengguna</h4>
      </template>

      <b-form class="mt-2" @submit.prevent>
        <b-row>
          <!-- nama -->
          <b-col md="6">
            <b-form-group label="Nama" label-for="nama">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="nama"
                  placeholder="Nama"
                  v-model.trim="form.name"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col md="6">
            <b-form-group label="Email" label-for="email" class="w-100">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="email"
                  v-model.trim="form.email"
                  placeholder="john@example.com"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- whatsapp -->
          <b-col md="6">
            <b-form-group label="Whatsapp" label-for="whatsapp">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>+62</b-input-group-prepend>
                <b-form-input
                  type="number"
                  id="whatsapp"
                  placeholder="Whatsapp"
                  v-model.number="form.no_wa"
                />
              </b-input-group>
              <small class="text-danger">* tanpa 0 di depannya</small>
            </b-form-group>
          </b-col>

          <!-- sekolah -->
          <b-col md="6">
            <b-form-group
              label="Asal Sekolah"
              label-for="asal_sekolah"
              class="w-100"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HomeIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="asal_sekolah"
                  v-model.trim="form.sekolah_asal"
                  placeholder="SMA/MA"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- tanggal lahir -->
          <b-col md="6">
            <b-form-group label="Tanggal Lahir" label-for="tanggal_lahir">
              <b-form-datepicker
                id="tanggal_lahir"
                v-model="form.tanggal_lahir"
                class="mb-1"
                placeholder="Tanggal Lahir"
              />
            </b-form-group>
          </b-col>

          <!-- jenis kelamin -->
          <b-col md="6">
            <b-form-group label="Jenis Kelamin">
              <div class="d-flex justify-content-start">
                <b-form-radio
                  v-model="form.jenis_kelamin"
                  name="some-radios"
                  value="LAKI-LAKI"
                  >Laki Laki</b-form-radio
                >
                <b-form-radio
                  v-model="form.jenis_kelamin"
                  name="some-radios"
                  value="PEREMPUAN"
                  class="ml-2"
                  >Perempuan</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- provinsi -->
          <b-col md="6">
            <b-form-group label="Provinsi">
              <b-form-group>
                <v-select
                  v-model="form.province_id"
                  placeholder="--Provinsi--"
                  @input="changeProvinsi"
                  :options="optionProvinsi"
                  :reduce="(propinsi) => propinsi.id"
                  label="name"
                />
              </b-form-group>
            </b-form-group>
          </b-col>

          <!-- kab/kota -->
          <b-col md="6">
            <b-form-group label="Kab/Kota">
              <v-select
                v-if="form.province_id"
                v-model="form.city_id"
                @input="changeKabKota"
                placeholder="--Kab/Kota--"
                :options="optionKabKota"
                :reduce="(kota) => kota.id"
                label="name"
              />
              <v-select v-else disabled placeholder="Pilih provinsi dulu" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- kecamatan -->
          <b-col md="6">
            <b-form-group label="Kecamatan">
              <v-select
                v-if="form.city_id && form.province_id"
                v-model="form.district_id"
                placeholder="--Kecamatan--"
                :options="optionKecamatan"
                :reduce="(district) => district.id"
                label="name"
              />
              <v-select v-else disabled placeholder="Pilih Kab/Kota dulu" />
            </b-form-group>
          </b-col>

          <!-- alamat -->
          <b-col md="6">
            <b-form-group label="Alamat" label-for="alamat" class="w-100">
              <b-form-textarea
                v-model.trim="form.alamat"
                id="alamat"
                placeholder="Tuliskan Alamat anda"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Role -->
          <b-col md="6">
            <b-form-group label="Role">
              <v-select
                v-model="form.role"
                placeholder="--ROLE--"
                :options="ROLES"
                :reduce="(role) => role.value"
                label="label"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- password -->
        <!-- <b-row>
          <b-col md="6">
            <b-form-group label-for="register-password" label="Password" class="w-100">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>
                <b-form-input id="register-password" v-model="form.password" class="form-control-merge" :type="passwordFieldType" name="register-password" placeholder="············" />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">* panjang password minimal 7 karakter</small>
            </b-form-group>
          </b-col>
        </b-row> -->
      </b-form>

      <template #modal-footer="{ cancel, ok }">
        <b-button
          size="md"
          variant="outline-primary"
          @click="cancel(), cancelClick()"
          >Cancel</b-button
        >
        <b-button size="md" variant="success" @click="editUser(form.id), ok()"
          >Edit</b-button
        >
      </template>
    </b-modal>

    <!-- Delete User -->
    <b-modal id="reset-password" centered @hidden="cancelClick">
      <template #modal-header>
        <h4>Reset Password User</h4>
      </template>
      <b-form>
        <b-form-group>
          <p>
            Apakah anda akan mereset password untuk user ini?:
            <br />Password akan menjadi
            <span style="font-weight: 700">12345678 (default)</span>
          </p>
        </b-form-group>
      </b-form>

      <template #modal-footer="{ cancel, ok }">
        <b-button
          size="md"
          variant="outline-primary"
          @click="cancel(), cancelClick()"
          >Cancel</b-button
        >
        <b-button size="md" variant="danger" @click="resetPassword(), ok()"
          >Reset Password</b-button
        >
      </template>
    </b-modal>

    <div class="m-2 tw-mb-6">
      <!-- Table Top -->
      <b-row class="d-flex align-items-center justify-content-between">
        <!-- Per Page -->

        <search-input
          :placeholder="'Cari User berdasarkan Nama..'"
          :onInput="onSearch"
        />

        <!-- Search -->
        <div class="d-flex align-items-center justify-content-end">
          <!-- <b-form-input v-model="searchQuery" class="mr-1 d-inline-block" placeholder="Search..." /> -->
          <b-button class="mx-1" variant="primary" @click="fetchUser">
            <feather-icon icon="RefreshCcwIcon" class="mr-50" />
            <span class="text-nowrap">Refresh Data</span>
          </b-button>

          <b-button
            variant="primary"
            v-b-modal.tambah-user
            @click="fetchWilayah"
          >
            <span class="text-nowrap">Tambah Pengguna</span>
          </b-button>
        </div>
      </b-row>

      <b-row class="tw-mt-[18px]">
        <div class="tw-inline-flex">
          <custom-filter
            :data="filterList"
            :handleChange="handleChange"
            :filterValue="filterValue"
            :initialValue="initialValue"
          />
        </div>
      </b-row>
    </div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-table
        ref="refUserListTable"
        :busy="isLoading"
        class="position-relative"
        responsive
        :items="listUser"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <!-- {{data}} -->
            <!-- <b-link :to="{ name: 'apps-users-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">{{ data.item.fullName }}</b-link> -->
            <div class="d-flex justify-content-start align-items-center">
              <div class="mr-0 mr-md-1">
                <div>
                  <b-avatar
                    size="35"
                    v-if="data.item.avatar"
                    :src="data.avatar"
                    class="d-none d-md-block"
                  />
                  <b-avatar
                    size="35"
                    variant="primary"
                    v-else
                    :text="data.item.name.split('')[0]"
                    class="d-none d-md-block"
                  />
                </div>
              </div>
              <div>
                <h6 class="text-primary font-weight-bolder">
                  {{ data.item.name }}
                </h6>
                <small class="text-muted small">{{ data.item.email }}</small>
              </div>
            </div>
          </b-media>
        </template>

        <template #cell(no_wa)="data">
          <b-link
            v-if="data.item.no_wa"
            :href="`https://wa.me/+62${data.item.no_wa}`"
            target="_blank"
            >0{{ data.item.no_wa }}</b-link
          >
          <b-badge v-else variant="danger">Tidak tersedia</b-badge>
        </template>

        <!-- Column: Role -->
        <template #cell(jenis_kelamin)="data">
          <div class="text-center">
            <div v-if="data.item.jenis_kelamin === 'LAKI-LAKI'">
              <div class="mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-gender-male"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                  />
                </svg>
              </div>
              <div>{{ data.item.jenis_kelamin }}</div>
            </div>
            <div v-else-if="data.item.jenis_kelamin === 'PEREMPUAN'">
              <div class="mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-gender-female"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"
                  />
                </svg>
              </div>
              <div>{{ data.item.jenis_kelamin }}</div>
            </div>
            <div v-else>
              <b-badge variant="primary">Belum mengisi</b-badge>
            </div>
          </div>
        </template>

        <!-- <vs-switch v-model="switch1" /> -->

        <template #cell(is_active)="data">
          <!-- <vs-switch color="dark" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="switch5"></vs-switch> -->
          <b-row>
            <b-col cols="12">
              <!-- <b-form-checkbox checked="true" class="custom-control-primary" name="check-button" switch value="true" unchecked-value="false" v-model="isActive" @change="switchActive">
                <span class="switch-icon-left">
                  <span>Active</span>
                </span>
                <span class="switch-icon-right">
                  <span>Off</span>
                </span>
              </b-form-checkbox>-->
              <!-- Status : {{isActive}} -->
              <b-badge variant="primary" v-if="data.item.is_active"
                >Aktif</b-badge
              >
              <b-badge v-else variant="danger">Tidak Aktif</b-badge>
            </b-col>
          </b-row>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">{{ data.item.status }}</b-badge>
        </template>-->

        <!-- Column: Riwayat Tryout -->
        <template #cell(history_tryout)="data">
          <b-link
            :to="{
              name: 'manajemen-users-tryout-history',
              params: { id: data.item.id },
            }"
          >
            <span class="align-middle">Lihat Semua</span>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'manajemen-users-detail',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getUser(data.item.id)" v-b-modal.edit-user>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="getUser(data.item.id)"
              v-b-modal.reset-password
            >
              <feather-icon icon="ToolIcon" />
              <span class="align-middle ml-50">Reset Password</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="getUser(data.item.id)" v-b-modal.reset-password>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>-->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              @change="getData"
              v-model="current_page"
              :total-rows="total_rows"
              :per-page="per_page"
              first-number
              last-number
              class="mt-1 mb-0 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- <b-modal id="formTambahUser" ok-title="Simpan" centered size="lg" title="Large Modal" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="storeUser"></b-modal> -->
  </div>
</template>

<script setup>
import {
  BFormGroup,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BSpinner,
  BDropdownItem,
  BPagination,
  BFormRadio,
  BFormDatepicker,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ref, onMounted, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ROLES } from "@/constants/roles";
import { CABANG, JENIS_KELAMIN, KATEGORI_USER } from "@/constants/index";
import debounce from "lodash.debounce";

import repository from "@repofactory";
import SearchInput from "@/@core/components/base/SearchInput.vue";
import CustomFilter from "@/@core/components/base/CustomFilter.vue";
const user = repository.get("userRepository");
const apiProvinsi = repository.get("ProvinsiRepository");
const apiKota = repository.get("KotaRepository");
const apiKecamatan = repository.get("KecamatanRepository");

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRadio,
    BFormDatepicker,
    VBModal,

    vSelect,

    //validations
    ValidationProvider,
    ValidationObserver,
    SearchInput,
    CustomFilter,
  },
  directives: {
    "b-modal": VBModal,
  },
  mixins: [togglePasswordVisibility],

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  setup() {
    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const isError = ref(false);
    const toast = useToast();

    const isActive = ref(false);

    const listUser = ref([]);

    // let passwordFieldType = ref("password");

    const optionProvinsi = ref([]);
    const optionKabKota = ref([]);
    const optionKecamatan = ref([]);

    const loadingProvinsi = ref(false);
    const loadingKabKota = ref(false);
    const loadingKecamatan = ref(false);

    const filterList = ref([
      {
        name: "Cabang",
        list: CABANG,
      },
      {
        name: "Gender",
        list: JENIS_KELAMIN,
      },
      {
        name: "Kategori",
        list: KATEGORI_USER,
      },
    ]);

    const initialValue = ref({
      Cabang: null,
      Gender: null,
      Kategori: null,
    });

    const filterValue = ref({
      Cabang: null,
      Gender: null,
      Kategori: null,
    });

    const handleChange = (value) => {
      filterValue.value = { ...value };
    };

    //form input
    const form = ref({
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      province_id: null,
      city_id: null,
      district_id: null,
      alamat: null,
      no_wa: null,
      sekolah_asal: null,
      tanggal_lahir: null,
      jenis_kelamin: null,
      id: null,
      index: null,
    });

    const resetModal = () => {
      form.value = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        province_id: null,
        city_id: null,
        district_id: null,
        alamat: null,
        no_wa: null,
        sekolah_asal: null,
        tanggal_lahir: null,
        jenis_kelamin: null,
        id: null,
        index: null,
      };
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    let total_rows = ref(null);
    let per_page = ref(null);
    let last_page = ref(null);
    let current_page = ref(1);

    const keyword = ref("");

    const onSearch = (e) => {
      keyword.value = e.currentTarget.value;
    };

    const getData = (page) => {
      current_page.value = page;
      fetchUser(page, keyword.value, filterValue.value);
    };

    const fetchUser = async (page, search = "", filters) => {
      isLoading.value = true;
      await user
        .get(page, search, 10, filters)
        .then((response) => {
          isLoading.value = false;
          listUser.value = response.data.data.data;
          total_rows.value = response.data.data.total;
          per_page.value = response.data.data.per_page;
          last_page.value = response.data.data.last_page;
        })
        .catch((error) => {
          isLoading.value = false;
          isError.value = true;
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const getProvinsi = async () => {
      loadingProvinsi.value = true;
      await apiProvinsi
        .get()
        .then((response) => {
          optionProvinsi.value = response.data;
        })
        .catch((error) => {
          console.log("error bos");
        });
      loadingProvinsi.value = false;
    };

    const getKabKota = async () => {
      loadingKabKota.value = true;
      await apiKota
        .get(Number(form.value.province_id))
        .then((response) => {
          optionKabKota.value = response.data.cities;
        })
        .catch((error) => {
          console.log("error kota/ provinsi");
        });
      loadingKabKota.value = false;
    };

    const getKecamatan = async () => {
      loadingKecamatan.value = true;
      await apiKecamatan
        .get(Number(form.value.city_id))
        .then((response) => {
          optionKecamatan.value = response.data.districts;
        })
        .catch((error) => {
          console.log("error kecamatan");
        });
      loadingKecamatan.value = false;
    };

    onMounted(() => {
      fetchUser(current_page.value, keyword.value, filterValue.value);
    });

    watch(
      [() => keyword.value, () => filterValue.value],
      debounce(() => {
        current_page.value = 1;
        fetchUser(1, keyword.value, filterValue.value);
      }, 500)
    );

    let tampungancurrentUser = ref(null);
    let indexTampungan = ref(null);

    // Get User
    const getUser = (id) => {
      const index = listUser.value.findIndex((user) => user.id == id);
      indexTampungan.value = index;
      const currentUser = listUser.value[index];
      tampungancurrentUser.value = listUser.value[index];

      form.value = {
        id: currentUser.id,
        index: index,
        name: currentUser.name,
        email: currentUser.email,
        password: currentUser.password,
        password_confirmation: currentUser.password_confirmation,
        province_id: String(currentUser.province_id),
        city_id: String(currentUser.city_id),
        district_id: String(currentUser.district_id),
        alamat: currentUser.alamat,
        no_wa: currentUser.no_wa,
        sekolah_asal: currentUser.sekolah_asal,
        tanggal_lahir: currentUser.tanggal_lahir,
        jenis_kelamin: currentUser.jenis_kelamin,
        role:
          currentUser.role_id === 1
            ? "super-admin"
            : currentUser.role_id === 2
            ? "admin"
            : "siswa",
      };

      getProvinsi();
      getKabKota();
      getKecamatan();
    };

    const fetchWilayah = () => {
      getProvinsi();
      getKabKota();
      getKecamatan();
    };

    //submit User
    const submitUser = async () => {
      isSubmitting.value = true;

      const body = {
        name: form.value.name,
        email: form.value.email,
        province_id: Number(form.value.province_id),
        city_id: Number(form.value.city_id),
        district_id: Number(form.value.district_id),
        alamat: form.value.alamat,
        no_wa: form.value.no_wa,
        sekolah_asal: form.value.sekolah_asal,
        tanggal_lahir: form.value.tanggal_lahir,
        jenis_kelamin: form.value.jenis_kelamin,
        password: form.value.password,
        password_confirmation: form.value.password,
        role: form.value.role,
      };

      await user
        .create(body)
        .then((response) => {
          fetchUser(1, "", filterValue.value);
          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const editUser = async (id) => {
      isSubmitting.value = true;

      if (form.value.city_id == null) {
        form.value.district_id = null;
      }

      if (form.value.province_id == null) {
        form.value.city_id = null;
        form.value.district_id = null;
      }
      const body = {
        name: form.value.name,
        email: form.value.email,
        province_id: Number(form.value.province_id),
        city_id: Number(form.value.city_id),
        district_id: Number(form.value.district_id),
        alamat: form.value.alamat,
        no_wa: form.value.no_wa,
        sekolah_asal: form.value.sekolah_asal,
        tanggal_lahir: form.value.tanggal_lahir,
        jenis_kelamin: form.value.jenis_kelamin,
        role: form.value.role,
      };

      await user
        .update(body, form.value.id)
        .then(() => {
          fetchUser(1, "", filterValue.value);
          showToast("Notifikasi", "Berhasil Update User", "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.errors,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const resetPassword = async () => {

      const email = form.value.email

      await user
        .resetPassword(email)
        .then(() => {
          fetchUser(1, "", filterValue.value);
          showToast("Notifikasi", "Berhasil Reset Password", "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.errors,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    // Delete User
    const deleteUser = async (id) => {
      isSubmitting.value = true;

      await user
        .delete(id)
        .then((response) => {
          fetchUser(1, "", filterValue.value);

          showToast("Notifikasi", response.data.message, "primary");
          resetModal();

          isSubmitting.value = false;
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const switchActive = () => {
      alert(isActive.value);
    };

    const changeProvinsi = () => {
      form.value.city_id = "";
      getKabKota();
    };

    const changeKabKota = () => {
      form.value.district_id = "";
      getKecamatan();
    };

    const cancelClick = () => {
      form.value = {
        id: tampungancurrentUser.value.id,
        index: indexTampungan.value,
        name: tampungancurrentUser.value.name,
        email: tampungancurrentUser.value.email,
        password: tampungancurrentUser.value.password,
        password_confirmation: tampungancurrentUser.value.password_confirmation,
        province_id: String(tampungancurrentUser.value.province_id),
        city_id: String(tampungancurrentUser.value.city_id),
        district_id: String(tampungancurrentUser.value.district_id),
        alamat: tampungancurrentUser.value.alamat,
        no_wa: tampungancurrentUser.value.no_wa,
        sekolah_asal: tampungancurrentUser.value.sekolah_asal,
        tanggal_lahir: tampungancurrentUser.value.tanggal_lahir,
        jenis_kelamin: tampungancurrentUser.value.jenis_kelamin,
      };

      listUser.value[indexTampungan] = form.value;
    };

    const tableColumns = [
      { key: "id", label: "ID", sortable: true },
      {
        key: "name",
        label: "Nama",
      },
      {
        key: "no_wa",
        label: "Whatsapp",
      },
      {
        key: "jenis_kelamin",
        label: "Gender",
        thClass: "text-center",
      },
      {
        key: "is_active",
        label: "Is Active",
        tdClass: "text-center",
        thClass: "text-center",
      },
      {
        key: "history_tryout",
        label: "Riwayat Tryout",
        tdClass: "text-center",
        thClass: "text-center",
      },
      { key: "actions" },
    ];

    return {
      // state
      tableColumns,
      listUser,
      form,
      keyword,
      isLoading,
      loadingProvinsi,
      loadingKabKota,
      loadingKecamatan,
      isError,
      isSubmitting,
      isActive,
      optionProvinsi,
      optionKabKota,
      optionKecamatan,
      ROLES,
      current_page,
      total_rows,
      per_page,
      last_page,
      filterList,
      initialValue,
      filterValue,

      // method
      resetModal,
      submitUser,
      getUser,
      editUser,
      deleteUser,
      cancelClick,
      switchActive,
      fetchUser,
      resetPassword,
      onSearch,
      fetchWilayah,
      changeProvinsi,
      changeKabKota,
      getData,
      handleChange,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
