export const ROLES = [
  {
    label: "Super Admin",
    value: "super-admin",
  },
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Siswa",
    value: "siswa",
  },
];
